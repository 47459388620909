import { redirect, render } from 'vike/abort'
import { useProfileStore } from "@/pages/cs/profil/profileStore.ts";
import { useBusinessRequestStore } from "@/pages/cs/(businessrequest)/businessRequestStore";


export async function guard(pageContext: any) {
    // console.log("Main guard")
    // console.log("index guard: ", pageContext)

    if (pageContext.urlOriginal === '/' && pageContext.urlPathname != "/") {
        // console.log("rendered '/' instead of" + pageContext.urlPathname)
        // console.log("navigating to " + pageContext.urlPathname)
        throw redirect(pageContext.urlPathname)
    }

    const profileStore = useProfileStore()
    const businessRequestStore = useBusinessRequestStore()

    // console.log("hasChanges", profileStore.hasChanges)
    // console.log("leaveWithoutSaving", profileStore.leaveWithoutSaving)
    // console.log("onProfilePage", profileStore.onProfilePage)

    if (profileStore.hasChanges && !profileStore.blockingPageLeave && profileStore.onProfilePage) {
        if (window.event?.type === 'popstate') {
            if (!profileStore.leaveWithoutSaving && profileStore.hasChanges) {
                const elem: any = document.getElementById('modalbutton');
                elem?.click();
                profileStore.blockingPageLeave = true // set it to true to avoid infinite loop
                throw render('/cs/profil')
            } else {
                profileStore.resetFormStores()
                profileStore.clearData()
                profileStore.$dispose()
                // delete pinia?.state.value[profileStore.$id]
            }
        } else {
            if (!profileStore.leaveWithoutSaving && profileStore.hasChanges) {
                const elem: any = document.getElementById('modalbutton');
                elem?.click();
                profileStore.blockingPageLeave = true // set it to true to avoid infinite loop
                throw render('/cs/profil')
            } else {
                profileStore.resetFormStores()
                profileStore.clearData()
                profileStore.$dispose()
                // delete pinia?.state.value[profileStore.$id]
            }
        }
    }

    businessRequestStore.handleSaveChangeBeforeLeaving(pageContext)
}